body {
  
  font-family: sans-serif;
  background-image: url('stadium2.jpg');
  background-size: cover;
  background-attachment: fixed;
  margin-top: 80px;
}

header {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
}

header * {
  display: inline;
}

.drop-down li {
  display: block;
}

button {
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid gray;
  align-self: center;
  justify-self: center;
  font-size: 15px;
  margin-right: 10px;
}

button:hover {
  cursor: pointer;
  background-color: gray;
}


.drop-down{
  width: 200px;
  height: 300px;
  border: 1px solid gray;
  border-radius: 5px;
  list-style-type: none;
  text-align: left;
  overflow: scroll;
  padding: 0px;
  position: relative;
  z-index: 9;
  background-color: white;
  display: block;

}

.drop-down li:hover{
  background-color: gray;
}

header img {
  height: 120px;
  overflow: visible;
  align-self: baseline;
  padding: 10px;
}



.card{
  display: flex;
  flex-direction: column;
  border: 3px solid gray;
  margin: 10px;
  margin-left: 100px;
  border-radius: 20px;
  background-color: white;
  width: 550px;
}

.graph-stage {
  display: flex;
  flex-direction: column;
  width: 500px;

  border-radius: 10px;
}

.xbox {
  width: 20px;
  height: 20px;
  font-size: 18px;
  color: gray;
  margin: 5px;
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  padding: 2px;
  align-self: center;
}

.xbox:hover {
  color: red;
  cursor: pointer;
  background-color: gray;
}

.name-plate {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
 }

.name-plate * {
  align-self: center;
}

.wrapper {
  width: 400px;
  align-self: center;
}

.wrapper-top {
  width: 520px;
  align-self: center;
}

.name{
  font-size: 20px;
  padding: 10px;
  align-self: center;
}

.handle{
  padding: 20px;
  text-justify: center;
  align-self: center;
}

.followers{
  text-justify: center;
  padding: 10px;
  align-self: center;
}

#stage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

